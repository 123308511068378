<template>
  <div>
    <div :class="listClass" class="transition">
      <cardless-base>
        <slot name="header">
          <PageTitle :title="title" />
        </slot>

        <b-row>
          <div class="select-box">
            <GenericSelect
              name="unidade"
              labelKey="nome"
              route="unidade"
              v-model="unidade"
              @input="(v) => aplyFilter('filtrarUnidade', v)"
              :firstOption="{value: null, label: 'Unidade'}"
            ></GenericSelect>

            <GenericSelect
              name="subSetor"
              labelKey="nome"
              route="subSetor"
              v-model="subSetor"
              @input="(v) => aplyFilter('filtrarSubSetor', v)"
              :firstOption="{value: null, label: 'Area'}"
            ></GenericSelect>

          </div>
          <b-button
            v-if="!nofilters"
            @click="toggleFilters()"
            variant="none"
            class="btn toggle-button align-self-center"
            :data-cy="`Adicionar ${name}`"
            ><SlidersIcon
          /></b-button>
        </b-row>

        <TabelaProcesso
          v-if="tipoTabela === 'processos'"
          title="Últimos processos finalizados"
          :materiais="materiais"
          :errorMsg="errorMsg"
        />

        <TabelaLote
          v-else-if="tipoTabela === 'lote'"
          title="Materiais do lote"
          :materiais="materiais"
          :lote="loteSearched"
          :errorMsg="errorMsg"
          :isBusy="loading"
        />

        <TabelaProtocolo
          v-else-if="tipoTabela === 'protocolo'"
          title="Materiais do protocolo"
          :materiais="materiais"
          :protocolo="protocoloSearched"
          :errorMsg="errorMsg"
          :isBusy="loading"
        />

        <nav
          aria-label="Navegar entre as páginas"
          class="paginador"
          v-if="page"
        >
          <div>
            Mostrando resultados {{ first }} a {{ last }} de um total de
            <strong>{{ count }}</strong
            >.
          </div>
          <ul class="pagination justify-content-center mb-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="rowsPerPage"
            ></b-pagination>
          </ul>
        </nav>
      </cardless-base>
      <!-- template-base -->

      <div class="transition filters-aside p-4" :class="filterAsideClasses">
        <button
          variant="none"
          class="btn toggle-button p-0"
          @click="toggleFilters"
        >
          <x-icon />
        </button>
        <b-row align-h="between" class="pt-4 pb-4">
          <b-col> <h2 class="m-0">Filtro</h2> </b-col>
          <b-col class="text-right">
            <b-button
              type="button"
              variant="none"
              class="sm primary-light-contained-button"
              :disabled="loading"
              @click="update(1, 20)"
              >Buscar</b-button
            >
          </b-col>
        </b-row>
        <slot name="filters" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import loginService from '@/services/login';

import GenericApi from '@/services/genericRequest';
import GenericSelect from '@/components/Form/GenericSelect';

import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';

import TabelaProcesso from './TabelaProcessos';
import TabelaLote from './TabelaLote';
import TabelaProtocolo from './TabelaProtocolo';

export default {
  name: 'GenericTable',
  components: {
    CardlessBase,
    PageTitle,
    TabelaProcesso,
    TabelaLote,
    TabelaProtocolo,
    GenericSelect,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editRoute: {
      type: String,
      required: false,
    },
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'get';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    canShowAllResults: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    permissionsToWrite: {
      type: Array,
      default() {
        return [];
      },
    },
    idKey: {
      type: String,
      default: '',
    },
    nofilters: {
      type: Boolean,
      default: false,
    },
    noadd: {
      type: Boolean,
      default: false,
    },
    editRouteIndex: {
      type: Function,
      default: () => 0,
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lista: [],
      loading: false,
      errMsg: '',
      pagination: false,
      page: 0,
      pages: 1,
      rowsPerPage: 20,
      first: 0,
      last: 0,
      count: 0,
      currentPage: 1,
      materiais: [],
      tipoTabela: 'processos',
      protocoloSearched: '',
      loteSearched: '',

      CAN_WRITE: loginService.verifyPermissions(this.permissionsToWrite),

      showingFilters: false,
      unidade: null,
      subSetor: null,
      errorMsg: '',
    };
  },
  watch: {
    currentPage() {
      this.paginate(this.currentPage);
    },
  },
  computed: {
    linhas() {
      return this.lista.map((row) => {
        const idKey = this.idKey
        || Object.keys(row).find((key) => key.slice(0, 2) === 'id');

        const indexRoute = this.editRouteIndex(row);

        return {
          href: {
            name: this.editRoutes[indexRoute],
            params: { id: row[idKey] },
          },
          cols: this.colsMap(row),
          id: row[idKey],
        };
      });
    },
    parsedCols() {
      return this.cols.map((c, idx) => {
        if (this.colsName[idx]) return c;
        return { value: c, sortable: false };
      });
    },
    editRoutes() {
      return this.editRoute.split(',');
    },
    listClass() {
      return this.showingFilters ? 'w-75' : 'w-100';
    },
    filterAsideClasses() {
      return this.showingFilters ? 'show' : 'hidden';
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    aplyFilter(prop, val) {
      this.$emit(prop, val);
      this.update(1, 20);
    },
    moment(date) {
      return moment(date);
    },
    setStateDataTable() {
      this.page = 0;
      this.update(1, this.rowsPerPage);
    },
    changeResults(event) {
      this.page = 0;
      this.update(1, event.target.value);
    },
    paginate(p) {
      if (p === this.page) return;
      this.page = p;
      this.update(this.page, this.rowsPerPage);
    },
    async update(page = 1, rowsPerPage = 20) {
      this.loading = true;
      this.pagination = true;

      const { filters } = this;

      if (filters.loteEsterilizacao) {
        this.tipoTabela = 'lote';
        this.loteSearched = filters.loteEsterilizacao;
        this.protocoloSearched = '';
      } else if (filters.protocolo) {
        this.tipoTabela = 'protocolo';
        this.protocoloSearched = filters.protocolo;
        this.loteSearched = '';
      } else {
        this.loteSearched = '';
        this.protocoloSearched = '';
        this.tipoTabela = 'processos';
      }
      const routes = {
        processos: 'ciclo/listCiclo',
        lote: 'ciclo/listLoteInformation',
        protocolo: 'ciclo/listProtocoloInformation',
      };

      this.materiais = [];

      const route = routes[this.tipoTabela];

      return GenericApi.get(
        { page, rowsPerPage, filters },
        route,
      )
        .then((res) => {
          this.page = page;
          this.pages = res.pages;
          this.first = res.count === 0 ? 0 : (page - 1) * rowsPerPage + 1;
          this.last = res.rows.length + rowsPerPage * (page - 1);
          this.count = res.count || res.rows.length;
          this.rowsPerPage = rowsPerPage;
          this.materiais = res.rows;
          this.loading = false;
          this.errorMsg = '';
        })
        .catch((reason) => {
          this.errorMsg = reason.response && reason.response.data
            ? reason.response.data.error.errorMessage
            : reason.toString();
          this.protocoloSearched = '';
          this.loteSearched = '';
        })
        .then(() => {
          this.loading = false;
        });
    },
    toggleFilters() {
      this.showingFilters = !this.showingFilters;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;
$primary-color-40: #21cca9;
$neutral-color-80: #35384d;
$neutral-color-70: #5e627a;
$neutral-color-60: #7e829f;

.w-75 {
  padding: 0 0 0 1.6rem;
}

.invision-select {
  width: auto;

  color: #5e627a;
  background-color: #f0f1fc;

  border: none;
  margin-left: 12px;
}

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.feather {
  margin-bottom: 4px;
}
.spacer {
  margin-left: auto;
}

.transition {
  transition: 0.3s ease-in-out;
}

.filters-aside {
  z-index: 100;
  position: fixed;
  min-height: 100%;
  right: 0;
  top: 64px;
  background-color: white;
}
.filters-aside.show {
  width: 22%;
}
.filters-aside.hidden {
  width: 0 !important;
  padding: 0 !important;
}

label {
  color: $neutral-color-70;
}

.select-box {
  display: flex;
  width: 400px;
}

.row {
  justify-content: space-between;
  padding: 0 1rem;
}

.invision-select,
.invision-input,
.invision-select.invision-input,
.form-control.invision-input,
.invision-file.invision-input .b-form-file.invision-input {
  margin-right: 1rem;
}

select:active,
select:focus,
select:hover {
  color: #21cca9;
}
</style>
