<template>
  <div class="white-box">
    <main class="card invision-card elevate-2 borderless container padding">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <span class="title">{{ title }}</span>
          <b-table
            :fields="tableFields"
            :items="materiais"
            striped
            class="mt-3"
            responsive
            show-empty
            :empty-text="errorMsg || 'Não existem materiais selecionados'"
          >
            <template #cell(state)="{ item }">
              <b-button
                class="btn btn-sm text--black"
                variant="outline-dark"
                @click="openRemoveModal(item)"
              >
                <trash-2-icon class="w20px" />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </main>
    <Modal
      id="modalRemoveProcesso"
      ref="modalRemoveProcesso"
      title="Abortar processo"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <AbortoProcessoModal title="Abortar processo" :item="processos" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Utils/Modal';
import AbortoProcessoModal from './AbortoProcessoModal';

export default {
  components: {
    Modal,
    AbortoProcessoModal,
  },

  data() {
    return {
      tableFields: [
        {
          key: 'state',
          label: 'Abortar',
          sortable: false,
        },
        {
          key: 'material.descricao',
          label: 'Descrição',
          sortable: false,
        },
        {
          key: 'material.shownId',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'area',
          label: 'Área',
          sortable: false,
        },
        {
          key: 'processo',
          label: 'Processo',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Data',
          sortable: false,
        },
      ],
      processos: {},
    };
  },

  props: {
    materiais: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      required: true,
    },
  },

  methods: {
    openRemoveModal(item) {
      this.processos = item;
      this.$refs.modalRemoveProcesso.show();
    },
  },
};
</script>

<style scoped>
.white-box {
  margin-top: 2rem;
}
.card.invision-card.borderless {
  border: 0;
  padding: 2rem;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #35384d;
}
</style>
