<template>
  <main class="container">
    <div class="w">
      <b-row>
        <b-col>
          <span class="sub-title">Deseja realmente realizar aborto do {{ tipoDeAborto }} a seguir?</span>
          <div class="box">
            <ul>
              <li>Área: {{ materiais[0].subSetor }} </li>
              <li>Data: {{ materiais[0].date }}</li>
              <li>Lote: {{ materiais[0].lote }}</li>
            </ul>
          </div>
          <br />
          <div>
            <b-form-group label="Justificativa">
              <b-form-input
                v-model="observacaoAbortoProcesso"
                size="lg"
                :state="Boolean(observacaoAbortoProcesso)"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <Button
            id="button"
            @click="removeProcess()"
            text="Confirmar"
            :loading="isBusy"
          />
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import Button from '@/components/Utils/Button';

export default {
  components: {
    Button,
  },
  props: {
    materiais: {
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
    tipoDeAborto: {
      type: String,
      required: true,
    },
    lote: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      observacaoAbortoProcesso: '',
      isBusy: false,
    };
  },
  methods: {
    async removeProcess() {
      this.isBusy = true;
      if (!this.observacaoAbortoProcesso) {
        await swal({
          title: 'Erro',
          text: 'Justificativa é obrigatória para aborto de processo!',
          icon: 'error',
          button: 'Continuar...',
        });

        return;
      }
      try {
        await GenericApi.delete(this.lote, 'ciclo/abortLote', {
          observacaoAbortoProcesso: this.observacaoAbortoProcesso,
        }, {
          abortData: this.materiais.map((m) => 
            ({ 
              id_processo_ciclo: m.processoCiclo.id_processo_ciclo, 
              shownId: m.shownId, 
              processoCiclo: m.processoCiclo || null
            })
          ),
          isLoteEsterilizacao: true,
        });
        this.$router.go();
      } catch (e) {
        console.log(e);
        swal({
          icon: 'error',
          text: 'Erro ao abortar lote. Contate o suporte',
          button: { confirm: 'Ok...' },
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.box {
  border: 1px dashed #AFB4D6;
  border-radius: 5px;
  padding: 2rem 0 1rem 2rem;
}

.box li {
  list-style: none;
  margin-bottom: 0.5rem;
}

#button {
  margin-top: 2rem;
  float: right;
}

.sub-title {
  font-size: 20px;
  display: flex;
  margin-top: -1rem;
  margin-bottom: 2rem;
  max-width: 300px;
  color: #21CCA9;
  text-align: center;
  margin-left: 12%;
}

li + li {
  margin-bottom: 0.5rem;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.w h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}
</style>
