<template>
  <div class="white-box">
    <main class="card invision-card elevate-2 borderless container padding">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <div class="table-header">
            <span class="title">{{ title }} {{ lote }}</span>
            <b-button
              type="button"
              variant="none"
              id="button"
              class="text-white primary-contained-button"
              @click="abortLote()"
            >ABORTAR LOTE</b-button>
          </div>
          <b-table
            :busy="isBusy"
            :fields="tableFields"
            :items="materiais"
            striped
            class="mt-3"
            responsive
            show-empty
            :empty-text="errorMsg || 'Não existem materiais selecionados'"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"/>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </main>
    <Modal
      id="modalRemoveProcesso modal-sm"
      ref="modalRemoveProcesso"
      title="Abortar processo"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
    <AbortoLoteModal
      title="Abortar lote"
      :materiais="materiais"
      :lote="lote"
      :tipoDeAborto="tipoDeAborto"
    />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Utils/Modal';
import AbortoLoteModal from './AbortoLoteModal';

export default {
  components: {
    Modal,
    AbortoLoteModal,
  },
  data() {
    return {
      tableFields: [
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: false,
        },
        {
          key: 'shownId',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'subSetor',
          label: 'Área',
          sortable: false,
        },
        {
          key: 'tipoProcesso',
          label: 'Processo',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Data',
          sortable: false,
        },
      ],
      tipoDeAborto: '',
    };
  },

  props: {
    materiais: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    lote: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async abortLote() {
      if (!this.materiais.length) {
        await swal({
          title: 'Ops',
          text: 'Nenhum material selecionado',
          icon: 'warning',
          button: 'Continuar...',
        });
        return;
      }
      this.tipoDeAborto = 'lote';
      this.$refs.modalRemoveProcesso.show();
    },
  },
};
</script>

<style scoped>
  .white-box {
    margin-top: 2rem;
  }
  .card.invision-card.borderless {
    border: 0;
    padding: 2rem;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #35384D;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
  }
</style>
